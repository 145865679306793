// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import CustomFieldItemResourceViewModel from '../../../../viewModel/resource/CustomFieldItemResourceViewModel';
import CustomFieldResourceViewModel from '../../../../viewModel/resource/CustomFieldResourceViewModel';
import CustomFieldResourceDTO from '../../dto/resource/CustomFieldResourceDTO';

export default abstract class CustomFieldResourceModel extends BaseModel<CustomFieldResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  * @type {int32}
  */
  get order() { return this.dto.order; }
  set order(value) { this.dto.order = value; }
  /**
  */
  get group() { return this.dto.group; }
  set group(value) { this.dto.group = value; }
  /**
  */
  get isRequired() { return this.dto.isRequired; }
  set isRequired(value) { this.dto.isRequired = value; }
  /**
  */
  get inputType() { return this.dto.inputType; }
  set inputType(value) { this.dto.inputType = value; }
  /**
  */
  get picklistItems() { return this.getArrayModels(() => this.dto.picklistItems, CustomFieldItemResourceViewModel)!; }
  set picklistItems(value) { this.setArrayModels(() => this.dto.picklistItems, value); }
  /**
  */
  get defaultValue() { return this.dto.defaultValue; }
  set defaultValue(value) { this.dto.defaultValue = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.order);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.order = value;
    this.dto.group = qs.group;
    value = qs.isRequired === 'true' ? true : qs.isrequired;
    value = qs.isRequired === 'false' ? false : undefined;
    this.dto.isRequired = value;
    this.dto.inputType = qs.inputtype;
    this.dto.defaultValue = qs.defaultvalue;
  }
}
