// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import CustomFieldItemResourceViewModel from '../../../../viewModel/resource/CustomFieldItemResourceViewModel';
import CustomFieldItemResourceDTO from '../../dto/resource/CustomFieldItemResourceDTO';

export default abstract class CustomFieldItemResourceModel extends BaseModel<CustomFieldItemResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  * @type {int32}
  */
  get order() { return this.dto.order; }
  set order(value) { this.dto.order = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.order);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.order = value;
  }
}
